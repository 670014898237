// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  ion-content{
    background-color: #F8F5F7;
  }
  /** primary **/
  --ion-color-primary: #ffe0e0;
  --ion-color-primary-rgb: 255, 224, 224;
  --ion-color-primary-contrast: #a0513c;
  --ion-color-primary-contrast-rgb: 160, 81, 60;
  --ion-color-primary-shade: #FFE0E0;
  --ion-color-primary-tint: #FFE0E0;

  //粉紅底 button /** secondary **/ 
  --ion-color-secondary: #A0513C;
  --ion-color-secondary-rgb: 160, 81, 60 ;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #A0513C;
  --ion-color-secondary-tint: #A0513C;

  //白底 標題\ion-badge /** tertiary **/ 
  --ion-color-tertiary: #A0513C;
  --ion-color-tertiary-rgb: 160, 81, 60;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #A0513C;
  --ion-color-tertiary-tint: #A0513C;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #F69E1C;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #F69E1C;
  --ion-color-warning-tint: #F69E1C;

  /** danger **/
  --ion-color-danger: #bc2d45;
  --ion-color-danger-rgb: 188, 45, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #BC2D45;
  --ion-color-danger-tint: #BC2D45;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #000000;

  /** medium **/
  --ion-color-medium: #6E6F73;
  --ion-color-medium-rgb: 110, 111, 115;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6E6F73;
  --ion-color-medium-tint: #6E6F73;

  /** light **/
  --ion-color-light: #A0513C;
  --ion-color-light-rgb: 160, 81, 60;
  --ion-color-light-contrast: #fef2f5;
  --ion-color-light-contrast-rgb: 254, 242, 245;
  --ion-color-light-shade: #A0513C;
  --ion-color-light-tint: #A0513C;
}

